import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Login from "../components/Auth/Login";
import Dashboard from "../components/Dashboard";
import Landing from "../components/Landing/Landing";
import Profile from "../components/Profile/Profile";
import Project from "../components/Projects/Project";
import Proposal from "../components/Proposals/Proposal";
import PageNotFound from "../components/ErrorPages/PageNotFound";
import ViewGrievances from "../components/Grievances/Kada/ViewGrievances";
import ChangePassword from "../components/Auth/ChangePassword/ChangePassword";
import NewLanding from "../components/Landing/NewLanding";
import Department from "../components/Departments/Department";

const allPublicRoutes = [
  { path: "/", component: <Landing /> },
  { path: "/login", component: <Login /> },
  { path: "/page-not-found", component: <PageNotFound /> },
];
const allPrivateRoutes = [
  { path: "/profile", component: <Profile /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/project/:id", component: <Project /> },
  { path: "/proposal/:id", component: <Proposal /> },
  { path: "/department/:id", component: <Department /> },
  { path: "/grievance/:id", component: <ViewGrievances /> },
  { path: "/change-password", component: <ChangePassword /> },
];

function ProjectRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          {allPublicRoutes.map(({ path, component }, index) => (
            <Route key={index} path={path} element={component} />
          ))}
        </Route>
        <Route element={<PrivateRoutes />}>
          {allPrivateRoutes.map(({ path, component }, index) => (
            <Route key={index} path={path} element={component} />
          ))}
          <Route path="*" element={<PageNotFound isPrivate={true} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default ProjectRoutes;
