import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown, message, Space } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";

import { logoutAPI } from "../../services/auth";
import { clear, getItem } from "../../helpers/localStorage";
import { REFRESH_TOKEN } from "../../constants/defaultKeys";

export default function Profile() {
  const navigate = useNavigate();

  async function logout() {
    try {
      await logoutAPI({ refresh: getItem(REFRESH_TOKEN) });
    } catch (error) {
      message.error(error.response?.data?.message);
    } finally {
      clear();
      navigate("/");
    }
  }

  const items = [
    {
      key: "1",
      label: "Profile",
      onClick: () => navigate("/profile"),
    },
    {
      key: "2",
      onClick: () => navigate("/change-password"),
      label: "Change Password",
    },
    {
      key: "4",
      onClick: logout,
      label: "Logout",
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Space>
        <Avatar size={36} icon={<UserOutlined />} />
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}
