import { Button, Descriptions, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { getGrievanceAPI } from "../../../services/grievances";
import { downloadBase64File } from "../../../helpers/utils";

export default function ViewGrievances() {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  async function getGrievance() {
    try {
      const response = await getGrievanceAPI(id);
      setData(response.data?.data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) getGrievance();
  }, [id]);

  const items = [
    {
      key: "unique_id",
      label: "Grievance Id",
      children: `${data?.unique_id || "-"}`,
      span: 2,
    },
    {
      key: "aadhaar",
      label: "Aadhar Number",
      children: `${data?.aadhaar || "-"}`,
      span: 2,
    },
    {
      key: "name",
      label: "Name",
      children: `${data?.name || "-"}`,
      span: 2,
    },
    {
      key: "mobile_number",
      label: "Contact Number",
      children: `${data?.mobile_number || "-"}`,
      span: 2,
    },
    {
      key: "age",
      label: "Age",
      children: `${data?.age || "-"}`,
      span: 1,
    },
    {
      key: "gender",
      label: "Gender",
      children: `${data?.gender || "-"}`,
      span: 1,
    },
    {
      key: "mandal_name",
      label: "Mandal",
      children: `${data?.mandal_name || "-"}`,
      span: 2,
    },
    {
      key: "secretariat_name",
      label: "Secretariat",
      children: `${data?.secretariat_name || "-"}`,
      span: 2,
    },
    {
      key: "department_name",
      label: "Department",
      children: `${data?.department_name || "-"}`,
      span: 2,
    },
    {
      key: "attachment_urls",
      label: "Attachments",
      children: (
        <>
          {data?.attachment_urls?.length > 0 ? (
            <Space>
              {data?.attachment_urls?.map((item, index) => (
                <Button
                  type="link"
                  key={index}
                  onClick={() => downloadBase64File(item?.blob, item?.filename)}
                >
                  {item?.filename}
                </Button>
              ))}
            </Space>
          ) : (
            "-"
          )}
        </>
      ),
      span: 3,
    },
  ];

  return loading ? (
    <Spin />
  ) : (
    <Descriptions title={data?.name} bordered items={items} />
  );
}
