import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const Counter = ({ endValue }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, 
    onChange: (inView) => setIsVisible(inView),
  });

  const { number } = useSpring({
    from: { number: 0 },
    number: isVisible ? endValue : 0,
    config: { duration: 2000 },
  });

  return (
    <animated.div ref={ref}>
      {number.to((n) => Math.floor(n).toLocaleString())}
    </animated.div>
  );
};

export default Counter;
