import React from "react";
import mapImg from "../../assets/img/group-39226-1.png";
import "./NewLandingFooter.css";
import { MdEmail } from "react-icons/md";
import { FaLocationDot, FaPhone } from "react-icons/fa6";

const NewLandingFooter = () => {
  return (
    <>
      <div className="group-45" >
        <div className="overlap-7">
          <div className="first-row">
            <div className="left-column">
              <p className="stay-connected-with" id="contact-section">
                <span>Stay connected with KADA</span>
              </p>
              <p className="text-wrapper-18">
                We are here to answer your questions, listen to your feedback,
                and work together for a prosperous Kuppam. Reach out to us
                through our contact information below.
              </p>

              <div className="contact-details" id="contact-us">
                <h3>CONTACT DETAILS</h3>
             
                <div style={{ mariginTop: "0px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <MdEmail />

                    <a
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                      className="email"
                      href="mailto:office@email.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      projectdirector[at]gmail[dot]com
                    </a>
                  </div>

                  <div
                    className="phone-number"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FaPhone />
                    <p style={{fontSize:"16px"}}>+91 9858595730</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <FaLocationDot style={{ marginTop: "5px" }} />
                    <p className="address" style={{fontSize:"16px"}}>
                      O/o Project Director, KADA,
                      <br />
                      Integrated Office complex,
                      <br />
                      Nethaji Road, Kuppam - 517425,
                      <br />
                      Chittoor District - A.P. <br />
                    </p>
                  </div>
                </div>
              </div>

              <div className="links-to-download">
                <span>LINKS TO DOWNLOAD</span>
                <div className="download-items">
                  <div className="download-item" style={{fontSize:"16px"}}>Vision document</div>
                  {/* <div className="download-item">Newsletters</div>
                  <div className="download-item">Reports</div> */}
                </div>
              </div>
            </div>

            <div className="right-column">
              {/* <img className="group-46" alt="Map" src={mapImg} /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d972.8732884748838!2d78.34303683558197!3d12.746453600000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badb83495588541%3A0x691a29e40a86552c!2sKADA%20Office!5e0!3m2!1sen!2sin!4v1728468370995!5m2!1sen!2sin"
                width="100% "
                height="400"
                style={{ border: "0", borderRadius: "5px" }} // Use style object for inline styles
                allowFullScreen // Use camelCase for props in JSX
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Map Location" // Adding a title for accessibility
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLandingFooter;
