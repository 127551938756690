import React from "react";
import "./CommunityEmpowerment.css";
import communityEmpowermentImg1 from "../../assets/images/community-empowerment-img-1.jpeg";
import quotes from "../../assets/img/image.svg";
import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one";
import QueueAnim from "rc-queue-anim";

import { Col, Row } from "antd";

const CommunityEmpowerment = () => {
  return (
    <div className="community-empowerment">
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <div className="empowerment-title-and-description">
            <p className="empowering-our">
              <span className="orange-title">Empowering</span>{" "}
              <span
                style={{
                  fontSize: "44px",
                  fontWeight: "700",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                our community
              </span>
            </p>
            <p
              style={{
                fontSize: "16px",
                textAlign: "justify",
                fontWeight: "400",
                fontFamily: "'Open Sans', sans-serif",
                lineHeight: "32px",
              }}
            >
              KADA is built on the foundation of community engagement and
              stakeholder participation. Through workshops, cultural events,
              recognition programs, and leadership training, we are fostering a
              sense of ownership and collective responsibility among the people
              of Kuppam.
            </p>
          </div>
        </Col>
        <Col lg={11} xs={24} offset={1} style={{ alignContent: "center" }}>
          <div className="community-empowerment-quote">
            <img className="quote-image" alt="Quote" src={quotes} />
            <br />
            <span className="quote-content">
              Empowering Kuppam: Harnessing Collective Will for Unmatched Growth
              and Lasting Legacy.
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col
          lg={12}
          xs={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        ><OverPack style={{ overflow: "hidden", height: "auto" }}>
        <TweenOne
          key="0"
          animation={{ opacity: 1 }}
          className="code-box-shape"
          style={{ opacity: 0, marginBottom: 10 }}
        />
        <QueueAnim
          key="queue"
          leaveReverse
          style={{
           
            justifyContent: "space-between", // Use flexbox for layout
          }}
        >
          <Row key="a" gutter={[16, 16]}>
            <div
              className="empowerment-content"
              style={{ marginBottom: "30px" }}
            >
              <span>
                Encourage community-owned micro-enterprises, sharing profits and
                reinvesting locally. Create a digital marketplace for residents
                to offer skills and services, boosting income and reducing
                poverty.
              </span>
            </div>
          </Row>
          <Row  key="b" justify={"end"} gutter={[16, 16]}>
            <div
              className="empowerment-content"
              style={{ marginBottom: "30px" }}
            >
              <span>
                Develop an edutainment platform for sustainable living and
                financial literacy through videos, podcasts, and games. Use
                mobile learning units to teach digital literacy in rural areas,
                empowering residents economical.
              </span>
            </div>
          </Row>
          <Row key="c" gutter={[16, 16]}>
            <div className="empowerment-content" style={{ bottom: "0px" }}>
              <span>
                Set up interactive smart kiosks providing information,
                resources, and a platform for resident feedback. Use VR to show
                a future eco-friendly Kuppam, motivating residents to adopt
                sustainable practices.
              </span>
            </div>
          </Row>
          </QueueAnim>
          </OverPack>
        </Col>
        <Col lg={11} offset={1} xs={23}>
          <img
            className="group-44"
            alt="Group"
            src={communityEmpowermentImg1}
          />
        </Col>
      </Row>
      {/* <div className="group-41">
        <div className="image-column">
          <img className="group-42" alt="Group" src={group39224_1} />
          <img className="group-43" alt="Group" src={kidsStudyingImg} />
        </div>
        <img className="group-44" alt="Group" src={group39226} />
      </div> */}
    </div>
  );
};

export default CommunityEmpowerment;
