import axiosInstance from "../config/axios";

export function createGrievancesAPI(payload) {
  return axiosInstance.post("project-management/grievances/", payload);
}

export function updateGrievancesAPI(payload, id) {
  return axiosInstance.put(`project-management/grievances/${id}/`, payload);
}

export function getGrievancesAPI() {
  return axiosInstance.get("project-management/grievances/");
}

export function getGrievanceAPI(id) {
  return axiosInstance.get(`project-management/grievances/${id}/`);
}
