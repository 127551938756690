import { Button, Card, Col, Row } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import img1 from "../../assets/img/group-50.png";
import promotingHealthImg1 from "../../assets/images/promoting-health-img-1.jpg";
import promotingHealthImg2 from "../../assets/images/promoting-health-img-2.jpeg";
import promotingHealthImg3 from "../../assets/images/promoting-health-img-3.jpg";
import "./PromotingHealth.css";

const successStories = [
  {
    id: 1,
    imgSrc: promotingHealthImg1,
    alt: "Img 1",
    description:
      "Eye Screening Camp - Screened 6,600 people and completed 70 cataract surgeries. Delivery of 4,600 spectacles and completion of 1,180 cataract surgeries in progress with ETA as 25th October",
    person: "Dhirendra Kumar, Village",
  },
  {
    id: 2,
    imgSrc: "https://scontent.fhyd2-3.fna.fbcdn.net/v/t39.30808-6/459339736_1033035695497714_5961806482721085470_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=833d8c&_nc_ohc=cIZTdQd9llcQ7kNvgGCtYXC&_nc_zt=23&_nc_ht=scontent.fhyd2-3.fna&_nc_gid=Ah2dy8_SWGcyxg_E04Mi1vb&oh=00_AYC3jC8twwq9uGm8KyU0Jx7fJsFwvnvZqW5KrhflACOxBA&oe=670EE200",
    alt: "Img 2",
    description:
      'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
    person: "Dhirendra Kumar, Village",
  },
  {
    id: 3,
    imgSrc: promotingHealthImg3,
    alt: "Img 3",
    description:
      "Conducted a household level health survey to assess general health status, identify prevalent health issues, and develop strategies for improved healthcare delivery. ",
    person: "Dhirendra Kumar, Village",
  },
  // {
  //   id: 4,
  //   imgSrc: img1,
  //   alt: "Img 4",
  //   description:
  //     'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
  //   person: "Dhirendra Kumar, Village",
  // },
];

const PromotingHealth = () => {
  return (
    <>
      <div className="overlap-6" style={{paddingBottom:'50px'}}>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={24}>
            {/* <div className="left-column"> */}
            <p className="promoting-health-and">
              <span>Promoting </span>
              <span className="orange-title">Health</span>
              {/* <br /> */}
              <span> and </span>
              <span className="orange-title">Wellness</span>
            </p>
            <p className="ensuring-the-health">
              With Health and Wellness as Community Pillars, it is planned to
              introduce telemedicine and remote health monitoring for accessible
              healthcare. Wellness Centres to be established to offer mental
              health counselling, fitness programs, nutritional advice, and
              conduct financial and career workshops.
            </p>
            {/* <Button
              className="join-us"
              type="primary"
              size="large"
              style={{ backgroundColor: "#FC6E01" }}
            >
              JOIN US <ArrowRightOutlined />
            </Button> */}
            {/* </div> */}
          </Col>
        </Row>
        <div className="scrollable-row">
          {/* <Row gutter={[16, 16]} className="scrollable-row"> */}
          {successStories.map((story) => (
            // <Col key={story.id} xs={24} sm={10} md={6} className="story-card">
            <div className="story-card">
              <Card
              style={{height:"500px"}}
                hoverable
                cover={
                  // <video src={story.imgSrc} controls className="story-video" />
                  <img
                    className="success-stories-img"
                    src={story.imgSrc}
                    alt={story.alt}
                  />
                }
                className="success-stories-card"
              >
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "'Open Sans', sans-serif",
                  }}
                >
                  {story.description}
                </p>
                {/* <div className="person">{story.person}</div> */}
              </Card>
            </div>
            // </Col>
          ))}
        </div>
        {/* <div className="second-row"> */}{" "}
      </div>
    </>
  );
};

export default PromotingHealth;
