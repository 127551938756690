export function setItem(key, value, isObject = false) {
  const data = isObject ? JSON.stringify(value) : value;
  localStorage.setItem(key, data);
}

export function getItem(key, isObject = false) {
  const value = localStorage.getItem(key);
  const data = isObject ? JSON.parse(value) : value;
  return data;
}

export function clear() {
  localStorage.clear();
}
