import React from "react";
import { Result, Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function PageNotFound({ isPrivate }) {
  const navigate = useNavigate();

  return (
    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Result
          status={"404"}
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={() =>
                isPrivate ? navigate("/dashboard") : navigate("/")
              }
            >
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
}
