import React from "react";
import { Row, Col, Card } from "antd";
import "./SuccessStories.css";
import img1 from "../../assets/img/group-50.png";
const successStories = [
  {
    id: 1,
    imgSrc: 'https://thumbs.dreamstime.com/b/puttaparthi-andhra-pradesh-india-july-indian-family-near-house-copy-space-text-puttaparthi-andhra-pradesh-india-july-99145038.jpg',
    alt: "Img 1",
    description:
      'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
    person: "Dhirendra Kumar, Village",
  },
  {
    id: 2,
    imgSrc: 'https://sc0.blr1.digitaloceanspaces.com/large/896079-hqfgjxqfyd-1538047262.jpg',
    alt: "Img 2",
    description:
      'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
    person: "Dhirendra Kumar, Village",
  },
  {
    id: 3,
    imgSrc: 'https://www.skymetweather.com/content/wp-content/uploads/2014/06/rice-sowing-Copy-Copy.jpg',
    alt: "Img 3",
    description:
      'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
    person: "Dhirendra Kumar, Village",
  },
  // {
  //   id: 4,
  //   imgSrc: img1,
  //   alt: "Img 4",
  //   description:
  //     'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
  //   person: "Dhirendra Kumar, Village",
  // },
];

const SuccessStories = () => {
  return (
    <div className="success-container">
      <h2 
      className="success-title"
        
      >Success
        <span style={{color:"#111",marginLeft:"10px"}}>Stories</span>  
      </h2>
      <div className="scrollable-row">
        {/* <Row gutter={[16, 16]} className="scrollable-row"> */}
        {successStories.map((story) => (
          // <Col key={story.id} xs={24} sm={10} md={6} className="story-card">
          <div className="story-card">
            <Card
              // hoverable
              cover={
                // <video src={story.imgSrc} controls className="story-video" />
                <img
                  className="success-stories-img"
                  src={story.imgSrc}
                  alt={story.alt}
                />
              }
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign:"left",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                {story.description}
              </p>
              {/* <div className="person">{story.person}</div> */}
            </Card>
          </div>
          // </Col>
        ))}
      </div>
     
      {/* </Row> */}
    </div>
  );
};

export default SuccessStories;
