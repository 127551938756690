import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Col, Row, Typography } from "antd";

import eg from "../../assets/images/regional-dev.jpg";
import communityDev from "../../assets/images/cd.jpg";
import sdg from "../../assets/images/sdg.jpg";
import { Fragment } from "react";

const { Meta } = Card;

export default function LatestNews() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const blogs = [
    {
      id: 1,
      title:
        "Transforming Kuppam: How KADA's Vision Drives Regional Development and Growth",
      img: eg,
    },
    {
      id: 2,
      title:
        "Innovating for Prosperity: The KADA Approach to Eradicating Poverty and Enhancing Quality of Life",
      img: communityDev,
    },
    {
      id: 3,
      title:
        "KADA's Blueprint for Success: Implementing Sustainable Development Strategies in Kuppam",
      img: sdg,
    },
  ];

  return (
    <Fragment>
      <Typography.Title style={{ margin: "2rem 0" }}>
        Upcoming projects
      </Typography.Title>
      <Row>
        {blogs.map((blog) => (
          <Col lg={8} md={8} sm={24} id={blog.id}>
            <Card
              cover={
                <img
                  src={blog.img}
                  alt={blog.title}
                  style={{
                    // border: "1px solid #F0F0F0",
                    objectFit: "cover",
                    height: "300px",
                    width: "100%",
                    borderBottom: "none",
                  }}
                />
              }
            >
              <Typography.Paragraph>{blog.title}</Typography.Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
}
