import React, { useEffect, useState } from "react";
import { Col, message, Row, Skeleton, Space, Typography } from "antd";
import { useParams } from "react-router-dom";

import Goals from "./Sections/Goals";
import Header from "./Sections/Header";
import Vision from "./Sections/Vision";
import Funding from "./Sections/Funding";
import Strategies from "./Sections/Strategies";
import { getDepartmentAPI } from "../../services/departments";
import CustomCard from "../../lib/CustomCard";
import { convertToIndianCrores } from "../../helpers/utils";

export default function Department() {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  async function getDepartmartData() {
    try {
      const response = await getDepartmentAPI(id);
      setData(response.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) getDepartmartData();
  }, [id]);

  return loading ? (
    <Skeleton active />
  ) : (
    <Row gutter={[32, 32]}>
      <Col span={9} offset={1}>
        <Header data={data} />
      </Col>
      <Col span={8}>
        <CustomCard title="Funding" style={{ height: "360px" }}>
          <Funding data={data} />
          <Space
            style={{
              marginTop: "10px",
              display: "flex",
              fontSize: "13px",
              justifyContent: "space-around",
            }}
          >
            {data?.total_estimated_cost ? (
              <Space direction="vertical">
                <Typography.Text style={{ color: "#00C49F" }}>
                  Total Estimated:
                </Typography.Text>
                <Typography.Text strong style={{ color: "#00C49F" }}>
                  {convertToIndianCrores(data?.total_estimated_cost)} Crores
                </Typography.Text>
              </Space>
            ) : null}
            {data?.central_funding ? (
              <Space direction="vertical">
                <Typography.Text style={{ color: "#FFBB28" }}>
                  Central:
                </Typography.Text>
                <Typography.Text strong style={{ color: "#FFBB28" }}>
                  {convertToIndianCrores(data?.central_funding)} Crores
                </Typography.Text>
              </Space>
            ) : null}
            {data?.state_funding ? (
              <Space direction="vertical">
                <Typography.Text style={{ color: "#0088FE" }}>
                  State:
                </Typography.Text>
                <Typography.Text strong style={{ color: "#0088FE" }}>
                  {convertToIndianCrores(data?.state_funding)} Crores
                </Typography.Text>
              </Space>
            ) : null}
          </Space>
        </CustomCard>
      </Col>
      <Col span={6}>
        <Vision data={data} />
      </Col>
      <Col span={22} offset={1}>
        <Goals data={data} id={id} />
      </Col>
      <Col span={22} offset={1}>
        <Strategies data={data?.strategies || []} />
      </Col>
    </Row>
  );
}
