import React from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { LockOutlined } from "@ant-design/icons";

import CustomCard from "../../../lib/CustomCard";
import JSEncrypt from "jsencrypt";
import { PUBLIC_KEY, USER } from "../../../constants/defaultKeys";
import { changePasswordAPI } from "../../../services/auth";
import { getItem } from "../../../helpers/localStorage";

export default function ChangePassword() {
  const user = getItem(USER, true);
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);

  async function onFinish(values) {
    try {
      const data = {
        ...values,
        token: encrypt.encrypt(user?.username),
        old_password: encrypt.encrypt(values?.old_password),
        new_password: encrypt.encrypt(values?.new_password),
        confirm_password: encrypt.encrypt(values?.confirm_password),
      };
      const response = await changePasswordAPI(data);
      message.success(response.data.message);
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
  return (
    <Row>
      <Col span={8} offset={8}>
        <CustomCard bordered={false}>
          <Form name="setpassword" onFinish={onFinish}>
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message:
                    "Use 8 or more characters with a mix of letters, numbers & symbols.",
                  pattern: new RegExp(
                    "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                  ),
                },
              ]}
            >
              <Input.Password
                autoFocus={true}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Current Password"
                size="large"
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message:
                    "Use 8 or more characters with a mix of letters, numbers & symbols.",
                  pattern: new RegExp(
                    "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                  ),
                },
              ]}
            >
              <Input.Password
                autoFocus={true}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="New Password"
                size="large"
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                { required: true, message: "Please input your Password!" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The new passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Confirm Password"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                className="login-form-button"
              >
                <span>Change</span>
              </Button>
            </Form.Item>
          </Form>
        </CustomCard>
      </Col>
    </Row>
  );
}
