import React, { lazy, Suspense, useMemo } from "react";
import { Spin } from "antd";

import useQueryParams from "../hooks/useQueryParams";

const compToLoad = {
  home: lazy(() => import("./Home/Home")),
  projects: lazy(() => import("./Projects/Projects")),
  proposals: lazy(() => import("./Proposals/Proposals")),
  grievances: lazy(() => import("./Grievances/Grievances")),
  departments: lazy(() => import("./Departments/Departments")),
  stakeHolders: lazy(() => import("./StakeHolders/StakeHolders")),
};

export default function Dashboard() {
  const { getParams } = useQueryParams();
  const params = getParams();

  const ActiveComponent = useMemo(
    () => compToLoad[params?.comp] || compToLoad["home"],
    [params?.comp]
  );
  return (
    <Suspense fallback={<Spin />}>
      <ActiveComponent />
    </Suspense>
  );
}
