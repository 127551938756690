import { Col, Image, Menu, Row, Typography } from "antd";

import Profile from "./Profile";
import logo from "../../assets/logos/logo.png";
import babu from "../../assets/logos/babu_icon.png";
import useQueryParams from "../../hooks/useQueryParams";

import "./Toolbar.css";

function Toolbar() {
  const { getParams, setParams } = useQueryParams();
  const params = getParams();

  const menuItems = [
    { label: "Home", key: "home" },
    { label: "Departments", key: "departments" },
    { label: "Projects", key: "projects" },
    { label: "Proposals", key: "proposals" },
    { label: "Grievances", key: "grievances" },
    { label: "Stake Holders", key: "stakeHolders" },
  ];

  return (
    <Row
      style={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        display: "flex",
      }}
    >
      <Col lg={{ span: 1 }} md={{ span: 2 }} sm={{ span: 4 }} xs={{ span: 4 }}>
        <Image
          src={logo}
          alt="logo"
          preview={false}
          style={{ height: "12vh", width: "auto" }}
        />
      </Col>
      <Col
        lg={{ span: 7 }}
        md={{ span: 5 }}
        sm={{ span: 0 }}
        xs={{ span: 0 }}
        style={{ textAlign: "center" }}
      >
        <Typography.Title
          level={2}
          style={{ marginBottom: "0px", marginTop: "0px" }}
        >
          K A D A
        </Typography.Title>
        <Typography.Paragraph
          style={{ marginBottom: "0px", marginTop: "0px", fontSize: "15px" }}
        >
          Kuppam Area Development Authority
        </Typography.Paragraph>
      </Col>
      <Col
        lg={{ span: 13 }}
        md={{ span: 13 }}
        sm={{ span: 12 }}
        xs={{ span: 12 }}
        style={{ textAlign: "right" }}
      >
        <Menu
          className="toolbar-menu"
          onClick={(e) => setParams("comp", e.key)}
          selectedKeys={[params?.comp || "home"]}
          mode="horizontal"
          items={menuItems}
        />
      </Col>
      <Col lg={{ span: 1 }} md={{ span: 2 }} sm={{ span: 4 }} xs={{ span: 4 }}>
        <Profile />
      </Col>
      <Col
        lg={{ span: 1 }}
        md={{ span: 2 }}
        sm={{ span: 4 }}
        xs={{ span: 4 }}
        style={{ textAlign: "right" }}
      >
        <Image
          src={babu}
          style={{ height: "11vh", width: "auto" }}
          preview={false}
        />
      </Col>
    </Row>
  );
}

export default Toolbar;
