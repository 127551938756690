import axiosInstance from "../config/axios";

export function createDepartmentAPI(payload) {
  return axiosInstance.post("project-management/departments/", payload);
}

export function getDepartmentsAPI(dashboard) {
  return axiosInstance.get("project-management/departments/", {
    params: dashboard && { dashboard },
  });
}

export function getProposalDepartmentsAPI() {
  return axiosInstance.get("project-management/get-proposal-departments/");
}

export function getDepartmentAPI(id) {
  return axiosInstance.get(`project-management/departments/`, {
    params: { department_id: id },
  });
}
