import { ACCESS_TOKEN, USER } from "../constants/defaultKeys";
import { clear, getItem } from "./localStorage";
import { ToWords } from "to-words";

export function isAuthenticated() {
  let isTokenAvailable = false;
  const access_token = getItem(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
}

export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};

export function generateRandomString(strLength = 6) {
  let result = "";
  let length = strLength;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const mobileNumberRegex = [
  {
    required: true,
    message: "Please enter number without any spaces or dashes",
    pattern: new RegExp("^[0-9]{10}$"),
  },
];

export const aadharNumberRegex = [
  {
    required: true,
    message: "Please enter valid aadhar number",
    pattern: new RegExp("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$"),
  },
];

export const emailRegex = [
  {
    required: true,
    pattern: new RegExp(
      "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,13})+$"
    ),
    message: "Please enter a valid email address.",
  },
];

export function convertToWords(number) {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
        },
      },
    },
  });

  return toWords.convert(number);
}

function getContentTypeByExtension(filename) {
  const extension = filename.split(".").pop();
  switch (extension) {
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "pdf":
      return "application/pdf";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "csv":
      return "text/csv";
    default:
      throw new Error("Unsupported file extension");
  }
}

export function downloadBase64File(base64Data, filename) {
  const contentType = getContentTypeByExtension(filename);
  const link = document.createElement("a");
  link.href = `data:${contentType};base64,${base64Data}`;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function truncateToFixed(num, decimalPlaces = 3) {
  let parts = num.toString().split(".");
  if (parts.length === 1 || decimalPlaces === 0) return parts[0];
  let decimalPart = parts[1].slice(0, decimalPlaces);
  return `${parts[0]}.${decimalPart}`;
}

export function convertToIndianCrores(number) {
  const crore = 1_00_00_000;

  if (number >= crore) return (number / crore).toFixed(2);
  else return number.toLocaleString("en-IN");
}

export function throttle(func, delay) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) return;
    lastCall = now;
    return func(...args);
  };
}
