import axiosInstance from "../config/axios";

export function createProposalsAPI(payload) {
  return axiosInstance.post("project-management/proposals/", payload);
}

export function getProposalsAPI(params) {
  return axiosInstance.get("project-management/proposals/", { params });
}

export function updateProposalsAPI(payload, params) {
  return axiosInstance.put("project-management/proposals/", payload, {
    params,
  });
}

export function createOrAddProposalsMovementAPI(payload, id) {
  return axiosInstance.post(
    `project-management/file-movements/${id}/`,
    payload
  );
}

export function getProposalAPI(params) {
  return axiosInstance.get("project-management/proposals/", { params });
}
