import React, { useEffect, useState } from "react";
import { Descriptions, List, message, Spin } from "antd";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { getProposalAPI } from "../../services/proposals";

export default function Proposal() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getDepartment() {
    try {
      const response = await getProposalAPI({ proposal_id: id });
      setData(response.data?.data);
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) getDepartment();
  }, [id]);

  const items = [
    {
      label: "Proposal Id",
      key: "unique_id",
      children: `${data?.unique_id || "-"}`,
      span: 2,
    },
    {
      label: "Date of Creation",
      key: "date_of_proposal",
      children: `${
        dayjs(data?.date_of_proposal)?.format("DD MMM YYYY") || "-"
      }`,
      span: 2,
    },
    {
      label: "Proposal Value (in cr)",
      key: "value",
      children: `${data?.value || "-"}`,
      span: 2,
    },
    {
      label: "Project Name",
      key: "project_name",
      children: `${data?.project_name || "-"}`,
      span: 2,
    },
    {
      label: "Department Name",
      key: "department_name",
      children: `${data?.department_name || "-"}`,
      span: 2,
    },
    {
      label: "Status",
      key: "status",
      children: `${data?.status || "-"}`,
      span: 2,
    },
    {
      label: "E-Office file number",
      key: "eoffice_file_name",
      children: `${data?.eoffice_file_name || "-"}`,
      span: 2,
    },
    {
      label: "Forwarded Date",
      key: "hierarchy_name",
      children: `${data?.hierarchy_name || "-"}`,
      span: 2,
    },
    {
      label: "Currently With",
      key: "current_file_movement",
      children: (
        <>
          {data?.current_file_movement?.department_name} -{" "}
          {data?.current_file_movement?.hierarchy_designation}
        </>
      ),
      span: 3,
    },
    {
      label: "Approval Hierarchy",
      key: "eoffice_file_name",
      children: (
        <List
          size="small"
          loading={loading}
          dataSource={data?.approval_hierarchy}
          className="dept-list"
          renderItem={(item) => (
            <List.Item>
              {item?.designation} - {item?.department_name}
            </List.Item>
          )}
        />
      ),
      span: 3,
    },
  ];

  console.log(data);

  return loading ? (
    <Spin />
  ) : (
    <Descriptions title={data?.name} bordered items={items} />
  );
}
