import { useState, useEffect } from "react";

const useViewPort = () => {
  const [viewport, setViewport] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateViewport = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateViewport);

    updateViewport(); // Initial values

    return () => window.removeEventListener("resize", updateViewport);
  }, []);

  return viewport;
};

export default useViewPort;
