import React from "react";
import { Outlet } from "react-router-dom";

import PublicLayout from "../layout/PublicLayout";

export default function PublicRoutes() {
  return (
    // <PublicLayout>
    <Outlet />
    // </PublicLayout>
  );
}
