export const USER = "user";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const TAG_COLORS = [
  "magenta",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];
export const COLORS = ["#0088FE", "#FFBB28", "#00C49F"];

export const PUBLIC_KEY = `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAkwM7P+l7ZxwEx8G9u/YhyYlWVW7tNqAc3AkhiCUH9SoTrFV47CoS
2M/OgSbAhSFvb//c4KJM0lw2Ak0VGJBi2LBcLStUPRFAihEJIb71xnoWTbHgJhqQ
4K49LrLqZO3XaAfHRuGW/URavJ0h69giXGXSDlKt9SNac1dYaQXOfZBdcgUi63uG
V+M078tVlYKEqPoT81nOtpTzAr8t3lRuDG2EBrcW33g/JuFKyRRiTeiL7AEEMnan
jqPKIP76n5IULtIhz/0iUCMhNnUmbYqBqPZfCqAjDjE8vuCu7WNR/RIc435p3/xF
ytQ5MXy82BTvUAgk4OepqrsbZqCozjQa/wIDAQAB
-----END RSA PUBLIC KEY-----`;
