import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tooltip } from "antd";

import AddOrUpdateGrievances from "./AddOrUpdateGrievances";
import { getGrievancesAPI } from "../../../services/grievances";

export default function Kada() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({ status: false, data: null, type: null });

  function onClose() {
    getGrievances();
    setOpen({ status: false, data: null, type: null });
  }

  const columns = [
    {
      title: "Grievance Id",
      dataIndex: "unique_id",
      key: "unique_id",
      width: 100,
      sorter: (a, b) => a.unique_id - b.unique_id,
    },
    {
      title: "Aadhar Number",
      dataIndex: "aadhaar",
      key: "aadhaar",
      width: 200,
      sorter: (a, b) => a.aadhaar - b.aadhaar,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: "Contact Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 200,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      width: 100,
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 100,
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      onFilter: (value, record) =>
        record?.gender?.toString().indexOf(value.toString()) === 0,
    },
    {
      title: "Mandal",
      dataIndex: "mandal_name",
      key: "mandal_name",
      width: 150,
      sorter: (a, b) => a.mandal_name?.length - b.mandal_name?.length,
    },
    {
      title: "Secretariat",
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      width: 150,
      sorter: (a, b) => a.secretariat_name?.length - b.secretariat_name?.length,
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: 150,
      sorter: (a, b) => a.department_name?.length - b.department_name?.length,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          {record?.status !== "approved" && (
            <Tooltip
              placement="top"
              title="Edit Project"
              onClick={() =>
                setOpen({ status: true, data: record, type: "edit" })
              }
            >
              <EditTwoTone />
            </Tooltip>
          )}

          <Tooltip
            placement="top"
            title="View Project"
            onClick={() => navigate(`/grievance/${record?.id}`)}
          >
            <EyeTwoTone />
          </Tooltip>
        </Space>
      ),
    },
  ];

  async function getGrievances() {
    try {
      const response = await getGrievancesAPI();
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getGrievances();
  }, []);

  return (
    <Row gutter={[0, 12]}>
      <Col span={24} style={{ textAlign: "right" }}>
        <Button
          type="primary"
          onClick={() => setOpen({ status: true, data: null, type: "add" })}
        >
          Add Grievance
        </Button>
      </Col>
      <Col span={24}>
        <Table
          scroll={{ x: 1000 }}
          pagination={false}
          dataSource={data}
          columns={columns}
          loading={loading}
          bordered
        />
      </Col>
      {open.status && (
        <AddOrUpdateGrievances
          addProposal={open.status}
          onClose={onClose}
          data={open.data}
          type={open.type}
        />
      )}
    </Row>
  );
}
