import { Button, Col, Divider, Row } from "antd";
import Banner from "./Banner";
import "./Landing.css";
import LandingHeader from "./LandingHeader";
import Services from "./Services";
import Ideological from "./Ideological";
import Vision from "./Vision";
import Stats from "./Stats";
import LatestNews from "./LatestNews";
import LandingFooter from "./LandingFooter";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Joinus from "./Joinus";
import Gallery from "./Gallery";
import NewVision from "./NewVision";
import NewStats from "./NewStats";
import ZeroPovertyStrategy from "./ZeroPovertyStrategy";
import SuccessStories from "./SuccessStories";
import PovertySection from "./PovertySection";
import Innovation from "./Innovation";
import CommunityEmpowerment from "./CommunityEmpowerment";
import PromotingHealth from "./PromotingHealth";
import NewLandingFooter from "./NewLandingFooter";
import FloatingGrievance from "./FloatingGrievance";

export default function Landing() {
  const location = useLocation();
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, [location]);
  return (
    <>
      <Row gutter={[16, 0]} className="hero-bg" id="#">
        <Col
          span={22}
          offset={1}
          style={
            {
              // marginTop: "1rem",
            }
          }
        >
          <Col
            span={24}
            style={{ position: "sticky", top: 0, position: "-webkit-sticky" }}
          >
            <LandingHeader />
          </Col>
        </Col>
        <Col span={22} offset={1}>
          <Banner />
        </Col>
      </Row>
      {/* <Row
        // className="tdp-bg"
        style={{ background: "#fff9ec" }}
      >
        <Col span={22} offset={1}>
          <Row style={{ margin: "1rem 0" }}>
            <Col span={24} id="idea">
              <Ideological />
            </Col>
          </Row>
        </Col>
      </Row> */}
      <div className="vision-container">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <NewVision />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="stats-container" id="join-us">
        <div className="stats-blur"></div>
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <NewStats />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="zero-poverty-strategy">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <ZeroPovertyStrategy />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="success-stories-container">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <SuccessStories />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="poverty-section-container">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <PovertySection />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="innovation-container">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <Innovation />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="community-empowerment-container">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <CommunityEmpowerment />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="promoting-health-container">
        <Row style={{}} id="progress" className="stats-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", color: "#fff !important" }}>
              <Col span={24}>
                <PromotingHealth />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <Row id="gallery" style={{ background: "#fff9ec" }}>
        <Col span={22} offset={1}>
          <Row style={{ margin: "2rem 0" }}>
            <Col span={24} id="progress">
              <Gallery />
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* <Row>
        <Col span={22} offset={1}>
          <Row style={{ margin: "2rem 0" }}>
            <Col span={24} id="progress">
              <LatestNews />
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* <div className="join-us-container" id="join-us">
        <div className="join-us-blur"></div>
        <Row className="join-us-content">
          <Col span={22} offset={1}>
            <Row style={{ margin: "2rem 0", minHeight: "400px" }}>
              <Col span={24}>
                <Joinus />
              </Col>
            </Row>
          </Col>
        </Row>
      </div> */}
      <Row className="footer-bg">
        <Col span={22} offset={1}>
          <Row style={{ margin: "2rem 0" }}>
            <Col span={24}>
              <NewLandingFooter />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider style={{ borderColor: "#fff" }} />
        </Col>
        <Col
          span={22}
          style={{
            margin: "1em",
            textAlign: "center",
            color: "#fff",
            fontSize: "16 px",
            fontWeight: "400",
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          Copyright © {new Date().getFullYear()} All Rights Reserved. KADA.
        </Col>
      </Row>
      <FloatingGrievance />
    </>
  );
}
