import React from "react";
import CustomCard from "../../../lib/CustomCard";

export default function Vision({ data }) {
  return (
    <CustomCard title="Vision" style={{ minHeight: "360px" }}>
      {data?.vision && <blockquote>"{data?.vision}"</blockquote>}
    </CustomCard>
  );
}
