import React from "react";
import { List } from "antd";
import CustomCard from "../../../lib/CustomCard";

export default function Strategies({ data }) {
  return (
    <CustomCard title="Strategies">
      <List
        // bordered
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              title={item.title}
              description={item?.description}
            />
          </List.Item>
        )}
      />
    </CustomCard>
  );
}
