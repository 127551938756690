import { JSEncrypt } from "jsencrypt";
import { SyncOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Form,
  Image,
  Input,
  Space,
  Button,
  message,
  Typography,
} from "antd";

import {
  emailLoginAPI,
  getCaptchaAPI,
  getNewCaptchaAPI,
} from "../../services/auth";
import CustomCard from "../../lib/CustomCard";
import logo from "../../assets/logos/logo.png";
import sunrise from "../../assets/images/sunrise_logo.png";
import { getItem, setItem } from "../../helpers/localStorage";
import {
  PUBLIC_KEY,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER,
} from "../../constants/defaultKeys";

import ClearDuplicateSession from "../ErrorPages/ClearDuplicateSession";

import "./Login.css";

export default function Login() {
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const navigate = useNavigate();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [captcha, setCaptcha] = useState({ captcha: null, token: null });

  async function getCaptcha() {
    try {
      const response = await getCaptchaAPI();
      setCaptcha(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const token = getItem(ACCESS_TOKEN);
    if (token) navigate("/dashboard");
    else getCaptcha();
  }, []);

  async function onFinish(values) {
    try {
      setLoading(true);

      const payload = {
        ...values,
        token: captcha.token,
        password: encrypt.encrypt(values.password),
      };

      if (showModal) payload.is_logout = true;
      const response = await emailLoginAPI(payload);
      const data = response.data.payload;
      setItem(USER, JSON.stringify(data));
      setItem(ACCESS_TOKEN, data.access_token);
      setItem(REFRESH_TOKEN, data.refresh_token);
      navigate("/dashboard");
    } catch (error) {
      const userAlreadyLoggedin = "user_already_loggedin";
      const errorCode = error.response.data.code;
      const errorMessage = error.response?.data?.message;
      if (errorCode === userAlreadyLoggedin && error.response.status === 401) {
        setShowModal(true);
      } else if (
        errorMessage === "captcha entered is wrong. Please try again"
      ) {
        getCaptcha();
        message.error(errorMessage);
      } else message.error(errorMessage);

      console.log(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function getNewCaptcha() {
    try {
      const res = await getNewCaptchaAPI({ token: captcha?.token });
      setCaptcha((prev) => ({ ...prev, captcha: res.data.captcha }));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Row align="middle" className="login">
      <Col
        lg={{ span: 8, offset: 2 }}
        md={{ span: 10, offset: 1 }}
        sm={{ span: 20, offset: 2 }}
        xs={{ span: 20, offset: 2 }}
      >
        <Image
          src={sunrise}
          preview={false}
          style={{ maxHeight: "70vh" }}
          onDragStart={(e) => e.preventDefault()}
        />
      </Col>
      <Col
        lg={{ span: 8, offset: 4 }}
        md={{ span: 10, offset: 1 }}
        sm={{ span: 20, offset: 2 }}
        xs={{ span: 20, offset: 2 }}
      >
        <CustomCard bordered={false}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Space direction="horizontal">
              <Image
                src={logo}
                alt="logo"
                preview={false}
                onDragStart={(e) => e.preventDefault()}
                style={{
                  paddingBottom: "14px",
                  height: "80px",
                }}
              />
              <Typography.Title level={4} style={{ marginTop: "0px" }}>
                Government of Andhra Pradesh
              </Typography.Title>
            </Space>
          </div>
          <Form
            name="login"
            onFinish={onFinish}
            Autocomplete="off"
            ref={formRef}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your email address",
                },
              ]}
            >
              <Input
                autoFocus={true}
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                // placeholder="s***@gmail.com or 1233210-da"
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <Input.Password
                autocomplete="new-password"
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <div>
              <Image
                src={`data:image/png;base64,${captcha.captcha}`}
                alt="Base64 Image"
                preview={false}
              />
              <SyncOutlined
                style={{ fontSize: "22px", marginLeft: "12px" }}
                onClick={getNewCaptcha}
              />
            </div>
            <Form.Item
              rules={[{ required: true, message: "Required" }]}
              name="captcha"
            >
              <Input placeholder="Submit captcha" size="large" />
            </Form.Item>

            <div style={{ textAlign: "right", paddingBottom: "10px" }}>
              <Link to="/forgot-password" className="footer-links">
                Forgot password?
              </Link>
            </div>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                className="login-form-button"
                loading={loading}
              >
                {loading ? "Logging In" : "Log In"}
              </Button>
            </Form.Item>
          </Form>
        </CustomCard>
      </Col>
      {showModal && (
        <ClearDuplicateSession
          formRef={formRef}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </Row>
  );
}
