import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../helpers/utils";

import DashboardLayout from "../layout/DashboardLayout";

function PrivateRoute() {
  return isAuthenticated() ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoute;
