import React, { Fragment } from "react";
import { Typography } from "antd";

export default function Header({ data }) {
  return (
    <Fragment>
      <Typography.Title level={2}>{data?.name}</Typography.Title>
      <Typography.Text>{data?.description}</Typography.Text>
    </Fragment>
  );
}
