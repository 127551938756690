import { Button, Descriptions, Image, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { getProjectAPI } from "../../services/projects";
import { TAG_COLORS } from "../../constants/defaultKeys";
import { downloadBase64File } from "../../helpers/utils";

export default function Project() {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  async function getProject() {
    try {
      const response = await getProjectAPI(id);
      setData(response.data?.data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) getProject();
  }, [id]);

  const items = [
    {
      key: "project_type",
      label: "Project Type",
      children: `${
        data?.project_type?.charAt(0).toUpperCase() +
          data?.project_type?.slice(1) || "-"
      }`,
      span: 2,
    },
    {
      key: "department_name",
      label: "Name of the Department",
      children: `${data?.department_name || "-"}`,
      span: 2,
    },
    {
      key: "cost",
      label: "Project Cost (in cr)",
      children: `${Number(data?.cost) || "-"}`,
      span: 2,
    },
    {
      key: "description",
      label: "Project Brief",
      children: `${data?.description || "-"}`,
      span: 2,
    },

    {
      key: "partners",
      label: "Project Partners",
      children: (
        <>
          {(data?.partners?.length > 0 &&
            data?.partners?.map(({ organization_name }, index) => (
              <Tag color={TAG_COLORS[index % TAG_COLORS?.length]} key={index}>
                {organization_name}
              </Tag>
            ))) ||
            "-"}
        </>
      ),
      span: 3,
    },
  ];

  if (data?.project_type === "ongoing") {
    items.push({
      key: "initiated_date",
      label: "Project Initiated Date",
      children: `${dayjs(data?.initiated_date)?.format("DD MMM YYYY") || "NA"}`,
      span: 2,
    });
    items.push({
      key: "completion_tentative_date",
      label: "Project Completion Tentative Date",
      children: `${
        dayjs(data?.completion_tentative_date)?.format("DD MMM YYYY") || "NA"
      }`,
      span: 2,
    });
    items.push({
      key: "photo_urls",
      label: "Photos",
      children: (
        <>
          {data?.photo_urls?.length > 0 ? (
            <Image.PreviewGroup>
              {data?.photo_urls?.map((photo) => (
                <Image
                  height={120}
                  style={{ paddingRight: "10px", paddingTop: "10px" }}
                  src={`data:image/jpeg;base64,${photo}`}
                />
              ))}
            </Image.PreviewGroup>
          ) : (
            "-"
          )}
        </>
      ),
      span: 3,
    });
    items.push({
      key: "attachment_urls",
      label: "Attachments",
      children: (
        <>
          {data?.attachment_urls?.length > 0 ? (
            <Space>
              {data?.attachment_urls?.map((item, index) => (
                <Button
                  type="link"
                  key={index}
                  onClick={() => downloadBase64File(item?.blob, item?.filename)}
                >
                  {item?.filename}
                </Button>
              ))}
            </Space>
          ) : (
            "-"
          )}
        </>
      ),
      span: 3,
    });
  }

  if (data?.project_type === "upcoming") {
    items.push({
      key: "start_tentative_date",
      label: "Project Start Tentative Date",
      children: `${
        dayjs(data?.start_tentative_date)?.format("DD MMM YYYY") || "NA"
      }`,
      span: 1,
    });
  }

  return loading ? (
    <Spin />
  ) : (
    <Descriptions title={data?.name} bordered items={items} />
  );
}
