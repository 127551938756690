import React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";

import { COLORS } from "../../../constants/defaultKeys";
import { convertToIndianCrores } from "../../../helpers/utils";

export default function Funding({ data }) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {convertToIndianCrores(value)}
      </text>
    );
  };

  const formatedData = [
    { name: "State", value: data?.state_funding },
    { name: "Central", value: data?.central_funding },
  ];

  return (
    <PieChart width={400} height={200} margin={{ bottom: 5 }}>
      {/* <Legend align="center" /> */}
      <Pie
        cx="50%"
        cy="50%"
        data={formatedData}
        fill="#8884d8"
        innerRadius={50}
        outerRadius={90}
        dataKey="value"
        labelLine={false}
        label={renderCustomizedLabel}
      >
        {formatedData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}
