import React from "react";
import { Card, Col, Row, Typography } from "antd";
import eg from "../../assets/images/economic-growth.png";
import poverty from "../../assets/images/poverty.jpeg";
import p4 from "../../assets/images/p4.webp";

const { Meta } = Card;

export default function Services() {
  const data = [
    {
      image: eg,
      title: "Economic Growth",
      src: "https://bytesed.com/wp/harnold/wp-content/uploads/2020/09/new-image-box-01-2x-370x270.jpg",
    },
    {
      image: poverty,
      title: "Eradicating Poverty",
      src: poverty,
    },
    {
      image: p4,
      title: "P4 Partnership",
      src: p4,
    },
  ];

  return (
    <Row justify="center" align="middle" gutter={[0, 30]}>
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <Typography.Title level={4} italic>
        కుప్పంలో పేదరికాన్ని పూర్తిగా నిర్మూలించడమే మా లక్ష్యం
        </Typography.Title> */}
        <Typography.Text>
<br /> A Visionary Path Forward. KADA is charting a course towards a prosperous and resilient Kuppam
        </Typography.Text>
      </Col>

      {data?.map((item, index) => (
        <Col key={index} span={8}>
          <Card
            className="transparent"
            styles={{
              body: {
                padding: 0,
              },
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              alt={item?.title}
              src={item.src}
              height={300}
              style={{
                width: "100%",
                objectFit: "cover",
                // overflow:"visible"
              }}
            />
            <Meta
              title={
                <p style={{ fontSize: "1.5rem", color: "white" }}>
                  {item?.title}
                </p>
              }
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
}
