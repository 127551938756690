import React from "react";
import "./ZeroPovertyStrategy.css";
import img1 from "../../assets/img/image-1.png";
import { Col, Row } from "antd";

const ZeroPovertyStrategy = () => {
  return (
    <Row gutter={[16, 16]} className="zero-poverty-container">
      <Col lg={12} xs={24} sm={24}>
        <img className="image-3" alt="Zero Poverty Strategy" src={img1} />
      </Col>
      <Col lg={1}></Col>
      <Col lg={11} xs={24} sm={24}>
        <div className="group-11">
          <p className="zero-poverty-strategy">
            <span className="orange-title">Zero poverty</span> strategy
          </p>
          <p className="our-zero-poverty">
            Our &#39;Zero Poverty&#39; strategy is driven by the P4 approach
            (Public-Private-People Partnership), aiming for sustainable
            development in 17 key sectors, including infrastructure, healthcare,
            education, sanitation, and employment. Through collaboration with
            government bodies, industry partners, academia, and citizens, we
            seek to eradicate poverty and create lasting economic opportunities
            for all.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default ZeroPovertyStrategy;
