import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useQueryParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  function getParams() {
    const params = {};
    for (let [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    return params;
  }

  function setParams(key, value, pathname) {
    const newQueryParams = new URLSearchParams(location.search);
    newQueryParams.set(key, value);

    navigate(
      {
        pathname: pathname || "/dashboard",
        search: newQueryParams.toString(),
      },
      { replace: true }
    );
  }

  return { getParams, setParams };
}

export default useQueryParams;
