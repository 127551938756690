import React from "react";
import cm from "../../assets/img/image-2.png";
import useViewPort from "../hooks/useViewPort";
import { Image } from "antd";
import kadaLogo from "../../assets/logos/kada_logo_2.png";
import { useNavigate } from "react-router-dom";

export default function Banner() {
  const { width } = useViewPort();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: `${width < 700 ? "column" : "row"}`,
        alignItems: "start",
        justifyContent: "center",
        height: "100%",
        marginBottom: "4em",
      }}
    >
       
      <div className="carousel-text" style={{marginTop:"90px"}}>
        <div className="carousel-logo" style={{display:"flex",marginBottom:`${width < 100 ? "" : "10px"}`}}>
        <Image
          src={kadaLogo}
          alt="Kada Logo"
          preview={false}
          style={{ height: `${width < 1600 ?"30vh":"30vh"}`, width: "auto" }}
          onClick={() => navigate("/#")}
        />
        </div>
        <h2
          className="line line1"
          style={{
            lineHeight: "1",
            fontSize: `${width < 1600 ? "4rem" : "5rem"}`,
            color: "#fff",
            fontFamily: "DM Serif Display",
            margin: "0",
          }}
        >
          <span >Building a</span>
        </h2>
        <h2
          className="line line2"
          style={{
            lineHeight: "1",
            fontSize: `${width < 1600 ? "4rem" : "5rem"}`,
            color: "#fff",
            fontFamily: "DM Serif Display",
            margin: "0",
          }}
        >
          <span style={{ color: "#fc6e01", fontStyle: "italic" }}>Stronger</span>
        </h2>
        <h2
          className="line line3"
          style={{
            lineHeight: "1",
            fontSize: `${width < 1600 ? "4rem" : "5rem"}`,
            color: "#fff",
            fontFamily: "DM Serif Display",
            margin: "0",
          }}
        >
          <span >Kuppam Community</span>
        </h2>
        <p
          className="line line4"
          style={{
            fontSize: "13px",
            fontWeight: "500",
            fontFamily: "'Open Sans', sans-serif",
            maxWidth: "700px",
            lineHeight: "32px",
            color: "#fff",
          }}
        >
          Kuppam's strength lies in its people. KADA focuses on empowering every
          citizen through inclusive development initiatives that promote
          education, healthcare, and economic growth.
        </p>
      </div>
      <img src={cm} alt={"CM"} className="cm-image" />
    </div>
  );
}
