import axiosInstance from "../config/axios";

export function uploadFileAPI(payload) {
  return axiosInstance.post("helpers/upload-files/", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteAttachmentsAPI(payload) {
  return axiosInstance.delete("helpers/upload-files/", payload);
}
