import React from "react";
import { ConfigProvider } from "antd";
import { setTwoToneColor } from "@ant-design/icons";

import ProjectRoutes from "./routes/Routes";

import "./App.css";

export default function App() {
  setTwoToneColor("#fc6e01");

  const customTheme = {
    token: {
      colorPrimary: "#fc6e01",
      borderRadius: 2,
      colorLink: "rgb(52, 138, 52)", // Custom link color
      colorLinkHover: "#d4b007",
      fontFamily: "Montserrat",
    },
  };
  return (
    <ConfigProvider theme={customTheme}>
      <ProjectRoutes />
    </ConfigProvider>
  );
}
