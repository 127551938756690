import { Col, Row } from "antd";
import dayjs from "dayjs";

import { getItem } from "../../helpers/localStorage";
import { USER } from "../../constants/defaultKeys";

const customParseFormat = require("dayjs/plugin/customParseFormat");
const advancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export default function Profile() {
  const user = getItem(USER, true);

  return (
    <Row>
      <Col span={24} style={{ textAlign: "right" }}>
        Last Logged in {dayjs(user?.last_login).format("DD MMM YYYY hh:mm A")}
      </Col>
    </Row>
  );
}
