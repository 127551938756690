import React from "react";
import img1_1 from "../../assets/images/stats.png";
import "./NewStats.css";
import { Col, Row } from "antd";
import Counter from "./Counter";
const NewStats = () => {
  return (
    <Row gutter={[16, 16]} className="stats-row">
      <Col xs={24} md={15}>
        <div className="statistics-container">
          <h2 className="statistics-heading">Metrics and Statistics</h2>
          <p className="kuppam-description">
            Kuppam, with an area of 1,051 sq. km, consists of four mandals:
            Kuppam, Gudupalle, Ramakuppam, and Santhipuram. With a population of
            285,123 (2011 Census), Kuppam stands at a literacy rate of 54% and
            is on a mission to achieve holistic growth across multiple sectors.
          </p>

          <div className="statistics-content">
  <Row gutter={[16, 16]} className="stats-grid">
    <Col xs={24}>
      <div className="stat-item">
        <div className="stat-label">Population</div>
        <div className="stat-value">
          <Counter endValue={285123} /> <span>(Urban: 21,963; Rural: 263,160)</span>
        </div>
      </div>
    </Col>

    <Col xs={24} md={24} lg={12}>
      <div className="stat-item">
        <div className="stat-label">Sex Ratio</div>
        <div className="stat-value">
          <Counter endValue={970} /> <span>females per 1000 males</span>
        </div>
      </div>
    </Col>
    
    <Col xs={24} md={24} lg={12}>
      <div className="stat-item">
        <div className="stat-label">Literacy Rate</div>
        <div className="stat-value">
          <Counter endValue={54} />%
        </div>
      </div>
    </Col>

    <Col xs={24} md={24} lg={8}>
      <div className="stat-item">
        <div className="stat-label">Municipalities</div>
        <div className="stat-value">
          <Counter endValue={1} />
        </div>
      </div>
    </Col>
    
    <Col xs={24} md={24} lg={8}>
      <div className="stat-item">
        <div className="stat-label">No. of Revenue Villages</div>
        <div className="stat-value">
          <Counter endValue={210} />
        </div>
      </div>
    </Col>
    
    <Col xs={24} md={24} lg={8}>
      <div className="stat-item">
        <div className="stat-label">Grama Panchayats</div>
        <div className="stat-value">
          <Counter endValue={93} />
        </div>
      </div>
    </Col>
  </Row>
</div>

        </div>
      </Col>

      <Col xs={24} md={9}>
        <div className="image-container">
          <img className="image" alt="Kuppam Statistics" src={img1_1} />
        </div>
      </Col>
    </Row>
  );
};

export default NewStats;
