import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  Drawer,
  message,
  InputNumber,
  Space,
} from "antd";
import JSEncrypt from "jsencrypt";

import {
  aadharNumberRegex,
  downloadBase64File,
  mobileNumberRegex,
} from "../../../helpers/utils";
import FileUpload from "../../../lib/FileUpload";
import { getRegionsAPI } from "../../../services/regions";
import {
  createGrievancesAPI,
  updateGrievancesAPI,
} from "../../../services/grievances";
import { PUBLIC_KEY } from "../../../constants/defaultKeys";
import { getDepartmentsAPI } from "../../../services/departments";

const { TextArea } = Input;

export default function AddOrUpdateGrievances({
  addProposal,
  onClose,
  data,
  type,
  landing = false,
}) {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);

  const [form] = Form.useForm();
  const [mandals, setMandals] = useState([]);
  const [loading, setLoading] = useState(false); // Start loading as false
  const [attachments, setAttachments] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [secretariats, setSecretariats] = useState([]);

  async function onFinish(values) {
    try {
      const formData = {
        ...values,
        encrypted_aadhar: encrypt.encrypt(values.aadhaar),
      };

      delete formData?.aadhaar;

      if (attachments?.length > 0) {
        formData["attachments"] = attachments.map(({ file }) => file);
      } else {
        delete formData["attachments"];
      }

      setLoading(true);
      const response =
        type === "add"
          ? await createGrievancesAPI(formData)
          : await updateGrievancesAPI(formData, data?.id);
      onClose(); // Close the drawer
      message.success(response?.data?.message);
    } catch (error) {
      message.error(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function getDepartments() {
    try {
      const response = await getDepartmentsAPI();
      const updatedData = response.data?.data?.map(({ name, id }) => ({
        label: name,
        value: id,
        text: name,
      }));
      setDepartments(updatedData);
    } catch (error) {
      message.error(error.response.data.message);
    }
  }

  async function getMandals() {
    try {
      const response = await getRegionsAPI();
      const formatedData = response.data?.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setMandals(formatedData);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAllData() {
    const promises = [getDepartments(), getMandals()];
    await Promise.allSettled(promises);
    setLoading(false);
  }

  useEffect(() => {
    fetchAllData();
    if (data) {
      form.setFieldsValue({
        ...data,
        mandal: data?.mandal_id,
        department: data?.department_id,
        secretariat: data?.secretariat_id,
      });
      if (data?.mandal_id) onMandalChange(data?.mandal_id);
    }
  }, [data, form]);

  async function onMandalChange(value) {
    try {
      const response = await getRegionsAPI({ type: "mandal", id: value });
      const formatedData = response.data?.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setSecretariats(formatedData);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Drawer title="Grievance" onClose={onClose} open={addProposal} width={980} style={{ padding: "40px" }}>
      <Form form={form} name="Grievance" layout="vertical" onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Aadhar Number"
              name="aadhaar"
              rules={type === "edit" ? [] : aadharNumberRegex}
            >
              <Input maxLength={12} disabled={type === "edit"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Contact Number"
              name="mobile_number"
              rules={mobileNumberRegex}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Age"
              name="age"
              rules={[{ required: true, message: "Please input age!" }]}
            >
              <InputNumber min={1} style={{ height: "40px", width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender!" }]}
            >
              <Select
                style={{ height: "40px" }}
                placeholder="select"
                options={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={landing ? 12 : 8}>
            <Form.Item
              label="Mandal"
              name="mandal"
              rules={[{ required: true, message: "Please select mandal!" }]}
            >
              <Select
                options={mandals}
                onChange={(value) => {
                  form.resetFields(["secretariat"]);
                  onMandalChange(value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={landing ? 12 : 8}>
            <Form.Item
              label="Secretariat"
              name="secretariat"
              rules={[{ required: true, message: "Please select secretariat!" }]}
            >
              <Select options={secretariats} />
            </Form.Item>
          </Col>

          {landing ? null : (
            <Col span={8}>
              <Form.Item
                label="Department"
                name="department"
                rules={[{ required: true, message: "Please select department!" }]}
              >
                <Select options={departments} />
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <Form.Item
              label="Subject"
              name="subject"
              style={{ height: "100px" }}
              rules={[{ required: true, message: "Please input subject!" }]}
            >
              <TextArea style={{ height: "100px" }} autoSize={{ minRows: 3 }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Attachments" name="attachments">
              <FileUpload
                formats={".doc,.docx,.pdf,.xlsx,.csv"}
                updateState={setAttachments}
              />
              {type !== "add" && (
                <Space>
                  {data.attachment_urls?.map((item, index) => (
                    <Button
                      type="link"
                      key={index}
                      onClick={() =>
                        downloadBase64File(item?.blob, item?.filename)
                      }
                    >
                      {item?.filename}
                    </Button>
                  ))}
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%", fontWeight: "bold", padding: "20px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
