import React from "react";
import { Layout } from "antd";

import "./Layout.css";
import PublicHeader from "../components/Toolbar/PublicHeader";

const { Header, Content, Footer } = Layout;

export default function PublicLayout({ children }) {
  return (
    <Layout className="layout" >
      <Header className="layout-header">
        <PublicHeader />
      </Header>
      <Content  className="layout-public-body">{children}</Content>
      <Footer className="layout-public-footer">
        © K A D A. All rights reserved.
      </Footer>
    </Layout>
  );
}
