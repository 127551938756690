import { Button, Modal } from "antd";
import React, { useState } from "react";
import Kada from "../Grievances/Kada/Kada";
import AddOrUpdateGrievances from "../Grievances/Kada/AddOrUpdateGrievances";

const FloatingGrievance = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="floating-grievance">
        <Button onClick={() => setShowModal(true)}>Grievance</Button>
      </div>

      {/* <Modal
        className="grievance-modal"
        closable={false}
        open={showModal}
        onCancel={() => setShowModal(false)}
      > */}

      {showModal && (
        <AddOrUpdateGrievances
          addProposal={true}
          onClose={() => setShowModal(false)}
          data={null}
          type={"add"}
          landing={true}
        />
      )}
      {/* </Modal> */}
    </>
  );
};

export default FloatingGrievance;
