import axiosInstance from "../config/axios";

export function emailLoginAPI(payload) {
  return axiosInstance.post("accounts/login/", payload);
}

export function changePasswordAPI(payload) {
  return axiosInstance.put("accounts/change-password/", payload);
}

export function getCaptchaAPI() {
  return axiosInstance.get("accounts/captcha/");
}

export function getNewCaptchaAPI(payload) {
  return axiosInstance.put("accounts/captcha/", payload);
}

export function logoutAPI(payload) {
  return axiosInstance.post("accounts/invalidate-refresh-token/", payload);
}
