import { Fragment } from "react";
import { Button, Modal, Result } from "antd";

export default function ClearDuplicateSession({
  formRef,
  showModal,
  setShowModal,
}) {
  return (
    showModal && (
      <Modal open={showModal} footer={false} closable={false}>
        <Result
          status="warning"
          title={
            <p>
              Oops! It seems you're already logged in from another device.
              Please log out from the other device before attempting to log in
              again.
            </p>
          }
          extra={
            <Fragment>
              <Button type="default" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button type="primary" onClick={() => formRef?.current?.submit()}>
                Continue
              </Button>
            </Fragment>
          }
        />
      </Modal>
    )
  );
}
