import React from "react";
import { Col, Image, Row, Menu, Button } from "antd";
import {
  ArrowRightOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apLogo from "../../assets/logos/logo.png";
import kadaLogo from "../../assets/logos/kada_logo_2.png";

function LandingHeader() {
  const navigate = useNavigate();
  const items = [
    { label: "Vision", key: "vision" },
    {
      label: "Projects",
      key: "projects",
    },
    {
      label: "Gallery",
      key: "gallery",
    },
    {
      label: "Contact Us",
      key: "contact-us",
    },
  ];

  return (
    <Row
      style={{
        display: "flex",
        alignItems: "center",
        position: "sticky",
        // top: "20px",
        // marginLeft: "130px",
      }}
    >
      <Col lg={2} md={8} sm={12} xs={8} style={{padding:"0"}}>
        <Image
          src={apLogo}
          alt="AP Logo"
          preview={false}
          style={{ height: "12vh", width: "auto", cursor: "pointer" ,marginTop:"15px"}}
          onClick={() => navigate("/#")}
        />
      </Col>
      <Col lg={2} md={8} sm={12} xs={8}>
       
      </Col>
      <Col lg={18} md={8} sm={0} xs={0} style={{}}>
        <Menu
          className="header-menu-items"
          style={{
            display: "flex",
            justifyContent: "right",
            textAlign: "center",
            background: "transparent",
            outline: "none",
            boxShadow: "none",
            border: "none",
            minWidth: "100%",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "29px",
            fontFamily: "'Open Sans', sans-serif",
          }}
          onClick={(item) => {
            navigate(`/#${item.key}`);
          }}
          mode="horizontal"
          items={items}
        />
      </Col>
      <Col style={{ textAlign: "right" }} lg={2} md={8} sm={12} xs={8}>
        <Button
          type="primary"
          style={{ background: "#fc6e01" ,marginLeft:"10px"}}
          size="large"
          onClick={() => navigate("/login")}
          className="login-button"
        >
          <span
            style={{
              fontSize: "1rem",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
          >
            Login
            <span
              style={{
                marginLeft: "5px",
                display: "inline-block",
                transition: "transform 0.3s ease",
              }}
              className="arrow-icon"
            >
              <ArrowRightOutlined />
            </span>
          </span>
        </Button>
      </Col>
    </Row>
  );
}

export default LandingHeader;
