import React from "react";
import { Card } from "antd";

import "./Custom.css";

export default function CustomCard(props) {
  let classes = "default-card";
  if (props.className) {
    classes = classes.concat(" ").concat(props.className);
  }

  return (
    <Card bordered={false} {...props} className={classes}>
      {props.children}
    </Card>
  );
}
