import axiosInstance from "../config/axios";

export function createProjectsAPI(payload) {
  return axiosInstance.post("project-management/projects/", payload);
}

export function getProjectsAPI() {
  return axiosInstance.get("project-management/projects/list/");
}

export function updateProjectAPI(payload, id) {
  return axiosInstance.put(`project-management/projects/${id}/`, payload);
}

export function getProjectAPI(id) {
  return axiosInstance.get(`project-management/projects/${id}/`);
}
