import React from "react";
import { Layout } from "antd";

import "./Layout.css";
import Toolbar from "../components/Toolbar/Toolbar";

const { Header, Content, Footer } = Layout;

function DashboardLayout({ children }) {
  return (
    <Layout>
      <Header className="layout-header">
        <Toolbar />
      </Header>
      <Content className="layout-body">{children}</Content>
      <Footer className="layout-footer">
        © K A D A. All rights reserved.
      </Footer>
    </Layout>
  );
}

export default DashboardLayout;
