import React from "react";
import { Col, List, Row, Segmented } from "antd";

import useQueryParams from "../../../hooks/useQueryParams";
import CustomCard from "../../../lib/CustomCard";

export default function Goals({ id, data }) {
  const { getParams, setParams } = useQueryParams();
  const params = getParams();

  const types = [
    { label: "Short Term", value: "short_term_goals" },
    { label: "Mid Term", value: "mid_term_goals" },
    { label: "Long Term", value: "long_term_goals" },
  ];
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Segmented
          options={types}
          onChange={(value) => setParams("type", value, `/department/${id}`)}
          value={params?.type}
        />
      </Col>
      <Col span={24}>
        <CustomCard title="Goals">
          <List
            // bordered
            itemLayout="horizontal"
            dataSource={data[params?.type || "short_term_goals"]}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={item.title}
                  description={item?.description}
                />
              </List.Item>
            )}
          />
        </CustomCard>
      </Col>
    </Row>
  );
}
